<template>
  <div class="page-table projeTanim  mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">Proje Güncelle</div>
    <el-row :gutter="10" v-loading="loading || sozlesmeLoading"
            :element-loading-text='loading ? $t("src.views.apps.proje.tanimlama.addLoading") : "Sözleşme Listesi Yükleniyor. Lütfen Bekleyiniz."'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="projeForm" :rules="rulesProje" ref="projeForm" class="demo-ruleForm">
        <el-col :lg="18" :md="18" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10">
            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Firma Adı" prop="musteriAdi">:
                  <el-select style="width: 100%" size="small" auto-complete="off" v-model="projeForm.musteriAdi"
                             placeholder="Firma Seçiniz" filterable :filter-methos="filterMethod">
                    <el-option v-for="item in musteriler" aria-autocomplete="none" :key="item.musteriID"
                               :label="item.musteriAdi"
                               :value="item.musteriID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="16" :md="16" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Proje Adı" prop="projeBaslik">:
                  <el-input auto-complete="off" size="small" v-model="projeForm.projeBaslik"
                            :placeholder="'Proje Adı Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Alan" prop="alan">:
                  <!--                  <el-input auto-complete="off" size="small" @input="formatAlan" v-model="projeForm.alan"-->
                  <!--                            :placeholder="'Alan Giriniz'"></el-input>-->
                  <el-input auto-complete="off" size="small" v-model="projeForm.alan"
                            :placeholder="'Alan Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Ada" prop="ada">:
                  <el-input auto-complete="off" size="small" v-mask="'##############'" v-model="projeForm.ada"
                            :placeholder="'Ada Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Parsel" prop="parsel">:
                  <el-input auto-complete="off" size="small" @input="validateParsel" v-model="projeForm.parsel"
                            :placeholder="'Parsel Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Ülke" prop="ulkeID">:
                  <el-select style="width: 100%" size="small" v-model="projeForm.ulkeID" @change="getIlListesi"
                             placeholder="Ülke Seçiniz" filterable :filter-methos="filterMethod">
                    <el-option
                        v-for="item in ulkeler"
                        :key="item.ulkeID"
                        :label="item.ulkeAdi"
                        :value="item.ulkeID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İl" prop="ilID">:
                  <el-select style="width: 100%" size="small" v-model="projeForm.ilID" @change="getIlceListesi"
                             placeholder="İl Seçiniz" filterable :filter-methos="filterMethod">
                    <el-option
                        v-for="item in iller"
                        :key="item.ilID"
                        :label="item.ilBaslik"
                        :value="item.ilID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İlçe" prop="ilceID">:
                  <el-select style="width: 100%" size="small" v-model="projeForm.ilceID" placeholder="İlçe Seçiniz"
                             filterable :filter-methos="filterMethod">
                    <el-option
                        v-for="item in ilceler"
                        :key="item.ilceID"
                        :label="item.ilceBaslik"
                        :value="item.ilceID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Adres" prop="adresBaslik">:
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      placeholder="Adres Giriniz"
                      v-model="projeForm.adresBaslik">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sozlesme.liste.table.sozlesmeAdi")'
                              name="6">
              <div style="padding: 0 10px 0 10px" class="kapsamlar sozlesmeSelect"
                   v-if="gruplanmisSozlesmeler.length > 0">
                <el-collapse-item v-for="(grup, grupIndex) in gruplanmisSozlesmeler" :key="grupIndex"
                                  :name="grup.sozlesmeKategoriID" :title="grup.sozlesmeKategoriAdi">
                  <el-row>
                    <el-col :span="24" class="flex-box"
                            style="padding: 0 5px 0 3px; height: 20px; margin-top: -5px;">
                      <el-form-item>
                        <div style="display: flex; align-items: center;">
                          <span style="margin-right: 5px;">Tümünü Seç</span>
                          <el-switch v-model="grup.grupSwitchState" @change="grupTumunuSec(grup)"
                                     active-color="#13ce66" inactive-color="#ff4949" active-value="1"
                                     inactive-value="0" style="text-align: right !important;"></el-switch>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="isDesktop && sozlesmeTarihBaslikGoster"
                          style="background-color: #f1f7fc !important; color: #00385a !important;"> <!--e2f1fc-->
                    <el-col :lg="6" :md="7" :sm="7" :xs="7">
                      <b>Sözleşme Kapsamları</b>
                    </el-col>
                    <el-col :lg="2" :md="2" :sm="2" :xs="2" style="padding: 0 30px 0 3px">
                      <div style="text-align:center"><b>Yüzde <br> Değer </b></div>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="3" :xs="3" style="padding: 0 40px 0 2px">
                      <div style="text-align:center"><b>Başlama <br> Süresi </b></div>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="3" :xs="3" style="padding: 0 50px 0 2px">
                      <div style="text-align:center"><b>İş <br> Süresi </b></div>
                    </el-col>
                    <el-col :lg="2" :md="3" :sm="3" :xs="3" style="padding: 0 90px 0 0px">
                      <div style="text-align:center"><b>Başlama <br> Tarihi</b></div>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="3" :xs="3" style="padding: 0 105px 0 3px">
                      <div style="text-align:center"><b>Tamamlanma <br> Tarihi</b></div>
                    </el-col>
                  </el-row>
                  <el-row v-if="isDesktop" v-for="(sozlesme, sozlesmeIndex) in grup.sozlesmeler" :key="sozlesmeIndex">
                    <el-col :lg="21" :md="21" :sm="21" :xs="21">
                      <el-col :lg="7" :md="7" :sm="7" :xs="7">
                        {{ sozlesme.sozlesmeBaslik }}
                      </el-col>
                      <el-col v-if="sozlesme.durum == '10' " :lg="2" :md="2" :sm="2" :xs="6"
                              style="padding: 0 2px 0 2px">
                        <el-tooltip content='Yüzde Değer' :open-delay="250" placement="bottom">
                          <el-input @input="sozlesme.yuzdeDeger = formatYuzdeDeger(sozlesme.yuzdeDeger)"
                                    @change="sozlesmeAtama(sozlesme)"
                                    v-model="sozlesme.yuzdeDeger" size="mini"></el-input>
                        </el-tooltip>
                      </el-col>
                      <el-col v-if="sozlesme.durum == '10'" :lg="3" :md="3" :sm="3" :xs="6"
                              style="padding: 0 2px 0 2px">
                        <el-tooltip content='Başlama Süresi' :open-delay="250" placement="bottom">
                          <el-input :input="sozlesmeSec(sozlesme)" v-mask="'#####'" v-model="sozlesme.baslangicSuresi"
                                    size="mini"></el-input>
                        </el-tooltip>
                      </el-col>
                      <el-col v-if="sozlesme.durum == '10'" :lg="3" :md="3" :sm="3" :xs="6"
                              style="padding: 0 2px 0 2px">
                        <el-tooltip content='İş Süresi' :open-delay="250" placement="bottom">
                          <el-input :input="sozlesmeSec(sozlesme)" v-mask="'#####'" v-model="sozlesme.isSuresi"
                                    size="mini"></el-input>
                        </el-tooltip>
                      </el-col>
                      <el-col v-if="sozlesme.durum == '10'" :lg="3" :md="4" :sm="4" :xs="6"
                              style="padding: 0 2px 0 2px; height: 25px !important;">
                        <el-form-item prop="sozlesmeBaslamaTarihi">
                          <el-tooltip :disabled="true" content='Sözleşme Başlama Süresi' :open-delay="250"
                                      placement="bottom">
                            <el-date-picker
                                style="position:relative; bottom: 2px"
                                v-model="sozlesme.baslamaTarihi"
                                size="mini" class="select-wide" type="date"
                                :placeholder='"Tarih Seçiniz"'
                                format="dd.MM.yyyy"
                                value-format="yyyy-MM-dd"
                                @input="baslamaSuresiDegistir($event, sozlesme)"
                                :picker-options="sozlesmeDatePickerOptions()"
                            >
                            </el-date-picker>
                          </el-tooltip>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="sozlesme.durum =='10'" :lg="3" :md="4" :sm="4" :xs="6"
                              style="padding: 0 2px 0 2px; height: 25px !important;">
                        <el-form-item prop="sozlesmeBitisTarihi">
                          <el-tooltip :disabled="true" content='Sözleşme Bitiş Tarihi' :open-delay="250"
                                      placement="bottom">
                            <el-date-picker
                                style="position:relative; bottom: 2px"
                                v-model="sozlesme.isTeslimTarihi"
                                size="mini" class="select-wide" type="date"
                                :placeholder='"Tarih Seçiniz"'
                                format="dd.MM.yyyy"
                                value-format="yyyy-MM-dd"
                                @input="bitisSuresiDegistir($event, sozlesme)"
                                :picker-options="sozlesmeDatePickerOptions(sozlesme.baslamaTarihi)"
                            >
                            </el-date-picker>
                          </el-tooltip>
                        </el-form-item>
                      </el-col>
                      <el-col :lg="2" :md="2" :sm="2" :xs="2">
                        <el-tooltip content="Bu tarihlerle kesişen proje bulunmaktadır."
                                    v-if="sozlesme.status == '10' && allSubArraysEmpty && cakismaIDs.includes(sozlesme.sozlesmeID)"
                                    :open-delay="500" placement="top">
                          <el-button
                              v-if="sozlesme.status == '10' && allSubArraysEmpty  && cakismaIDs.includes(sozlesme.sozlesmeID)"
                              @click="open(sozlesme.sozlesmeID)" type="text"
                              style="color: rgb(212, 5, 5); margin-left: 65px !important; margin-top: -10px;"
                              icon="mdi mdi-alert mdi-24px">
                          </el-button>
                        </el-tooltip>
                      </el-col>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="3" :xs="3">
                      <div style="text-align:center;">
                        <el-select @change="atama($event,sozlesme),sozlesmeAtama(sozlesme)"
                                   style="width: 100%" size="small" auto-complete="off"
                                   v-model="sozlesme.status"
                                   placeholder="Durum Seçiniz" filterable :filter-methos="filterMethod"
                                   :key="count[sozlesme.sozlesmeID]">
                          <el-option v-for="item in durumOptions" aria-autocomplete="none" :key="item.musteriID"
                                     :label="item.label"
                                     :value="item.key">
                          </el-option>
                        </el-select>
                        <!--                        <el-switch v-model="sozlesme.secili" active-color="#13ce66" inactive-color="#ff4949"-->
                        <!--                                   active-value="1" inactive-value="0"-->
                        <!--                                   @change="atama(sozlesme, sozlesme.secili)"-->
                        <!--                                   style="text-align: right !important;"></el-switch>-->
                      </div>
                    </el-col>
                  </el-row>
                  <el-row v-if="!isDesktop" v-for="(sozlesme, sozlesmeIndex) in grup.sozlesmeler" :key="sozlesmeIndex">
                    <el-col :lg="16" :md="16" :sm="16" :xs="16">
                      <el-row style="padding:0!important;">
                        <el-col :lg="24" :md="24" :sm="24" :xs="24"
                                style="place-content: start;place-items: center;display: flex">
                          {{ sozlesme.sozlesmeBaslik }}
                          <el-tooltip content="Bu tarihlerle kesişen proje bulunmaktadır."
                                      v-if="sozlesme.secili == '1' && allSubArraysEmpty && cakismaIDs.includes(sozlesme.sozlesmeID)"
                                      :open-delay="500" placement="top">
                            <el-button
                                v-if="sozlesme.secili == '1' && allSubArraysEmpty  && cakismaIDs.includes(sozlesme.sozlesmeID)"
                                @click="open(sozlesme.sozlesmeID)" type="text"
                                style="color: rgb(212, 5, 5); margin-left: 10px"
                                icon="mdi mdi-alert mdi-24px">
                            </el-button>
                          </el-tooltip>
                          <!-- <label v-if="allSubArraysEmpty && cakismaIDs.includes(sozlesme.sozlesmeID)" style="color: red;">{{ cakisma }}</label>-->
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :lg="8" :md="8" :sm="8" :xs="8">
                      <div style="text-align:center;" :key="count[sozlesme.sozlesmeID]">
                        <el-select @change="atama($event,sozlesme),sozlesmeAtama(sozlesme)"
                                   style="width: 100%" size="small" auto-complete="off"
                                   v-model="sozlesme.status"
                                   placeholder="Durum Seçiniz" filterable :filter-methos="filterMethod"
                                   :key="count[sozlesme.sozlesmeID]">
                          <el-option v-for="item in durumOptions" aria-autocomplete="none" :key="item.musteriID"
                                     :label="item.label"
                                     :value="item.key">
                          </el-option>
                        </el-select>
                      </div>
                    </el-col>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                      <el-row>
                        <el-col v-if="sozlesme.secili == '1'" :lg="8" :md="8" :sm="8" :xs="8">
                          <el-form-item label="Yüzde Değer" class="m-5">
                            <el-input @input="sozlesme.yuzdeDeger = formatYuzdeDeger(sozlesme.yuzdeDeger)"
                                      auto-complete="off" v-model="sozlesme.yuzdeDeger" size="mini"/>
                          </el-form-item>
                        </el-col>
                        <el-col v-if="sozlesme.secili == '1'" :lg="9" :md="9" :sm="9" :xs="9">
                          <el-form-item label="Başlama Süresi" class="m-5">
                            <el-input :input="sozlesmeSec(sozlesme)" auto-complete="off" v-mask="'#####'"
                                      v-model="sozlesme.baslangicSuresi" size="mini"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col v-if="sozlesme.secili == '1'" :lg="7" :md="7" :sm="7" :xs="7">
                          <el-form-item label="İş Süresi" class="m-5">
                            <el-input v-mask="'#####'" auto-complete="off"
                                      v-model="sozlesme.isSuresi" size="mini"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col v-if="sozlesme.secili == '1'" :lg="12" :md="12" :sm="12" :xs="12"
                                style="padding: 0 2px 0 2px; height: 25px !important;">
                          <el-form-item label="Başlama Tarihi" prop="sozlesmeBaslamaTarihi">
                            <el-date-picker
                                v-model="sozlesme.baslamaTarihi" size="mini" class="select-wide"
                                type="date" :placeholder='"Tarih Seçiniz"' format="dd.MM.yyyy"
                                value-format="yyyy-MM-dd" :picker-options="sozlesmeDatePickerOptions()"
                                @input="baslamaSuresiDegistir($event, sozlesme)">
                            </el-date-picker>
                          </el-form-item>
                        </el-col>
                        <el-col v-if="sozlesme.secili == '1'" :lg="12" :md="12" :sm="12" :xs="12"
                                style="padding: 0 2px 0 2px; height: 25px !important;">
                          <el-form-item label="Tamamlanma Tarihi" prop="sozlesmeBitisTarihi">
                            <el-date-picker
                                v-model="sozlesme.isTeslimTarihi" size="mini"
                                class="select-wide" type="date" :placeholder='"Tarih Seçiniz"'
                                format="dd.MM.yyyy" value-format="yyyy-MM-dd"
                                @input="bitisSuresiDegistir($event, sozlesme)"
                                :picker-options="sozlesmeDatePickerOptions(sozlesme.baslamaTarihi)"
                            >
                            </el-date-picker>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-collapse-item v-if="false" class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
            <div style="padding: 0 10px 0 10px" class="sozlesmeSelect" v-if="sozlesmeList.length > 0">
              <el-table :data="sozlesmeList" :key="sozlesmeKey" style="width: 100%">
                <el-table-column label="Sözleşme Kapsamları" width="250">
                  <template slot-scope="scope">
                    {{ scope.row.sozlesmeBaslik }}
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="Yüzde Değer" width="130">
                  <template slot-scope="scope">
                    <el-input @input="scope.row.yuzdeDeger = formatYuzdeDeger(scope.row.yuzdeDeger)"
                              v-model="scope.row.yuzdeDeger" size="mini" v-if="scope.row.secili"></el-input>
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="Başlama Süresi" width="130">
                  <template slot-scope="scope">
                    <el-input :input="sozlesmeSec(scope.row)" v-mask="'#####'" v-model="scope.row.baslangicSuresi"
                              size="mini" v-if="scope.row.secili"></el-input>
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="İş Süresi" width="130">
                  <template slot-scope="scope">
                    <el-input :input="sozlesmeSec(scope.row)" v-mask="'#####'" v-model="scope.row.isSuresi"
                              size="mini"></el-input>
                  </template>
                  <template slot-scope="scope">
                    <el-input :input="sozlesmeSec(scope.row)" v-mask="'#####'" v-model="scope.row.isSuresi" size="mini"
                              v-if="scope.row.secili"></el-input>
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="Başlama Tarihi" width="170">
                  <template slot-scope="scope">
                    <div style="display: flex; align-items: center; margin-top: 7px;">
                      <el-form-item prop="sozlesmeBaslamaTarihi" style="margin-bottom: 0;">
                        <el-tooltip content="Sözleşme Başlama Tarihi" :open-delay="250" placement="bottom">
                          <el-date-picker v-if="scope.row.secili"
                                          style="position:relative; bottom: 4px"
                                          v-model="scope.row.baslamaTarihi"
                                          size="mini" class="select-wide" type="date"
                                          :placeholder="'Tarih Seçiniz'"
                                          format="yyyy-MM-dd"
                                          value-format="yyyy-MM-dd"
                                          @change="baslamaSuresiDegistir($event, scope.row)"
                                          :picker-options="pickerOptions">
                          </el-date-picker>
                        </el-tooltip>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="Tamamlanma Tarihi" width="170">
                  <template slot-scope="scope">
                    <div style="display: flex; align-items: center; margin-top: 7px;">
                      <el-form-item prop="sozlesmeBitisTarihi" style="margin-bottom: 0;">
                        <el-tooltip content="Sözleşme Bitiş Tarihi" :open-delay="250" placement="bottom">
                          <el-date-picker v-if="scope.row.secili"
                                          style="position:relative; bottom: 4px"
                                          v-model="scope.row.isTeslimTarihi"
                                          size="mini" class="select-wide" type="date"
                                          :placeholder="'Tarih Seçiniz'"
                                          format="yyyy-MM-dd"
                                          value-format="yyyy-MM-dd"
                                          @change="bitisSuresiDegistir($event, scope.row)"
                                          :picker-options="datePickerOptions(scope.row.baslamaTarihi)">
                          </el-date-picker>
                        </el-tooltip>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot="header">
                    <div style="display: flex; justify-content: flex-end;">
                      <el-checkbox v-model="tumunuSec" @change="tumunuSecChange"></el-checkbox>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div style="text-align: right;">
                      <!--                      <el-checkbox v-model="scope.row.secili"-->
                      <!--                                   @change="sozlesmeSec(scope.row); sozlesmeKey++"></el-checkbox>-->
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-collapse-item>
        </el-col>
        <el-col :lg="6" :md="6" :sm="24" :xs="24">
          <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
            <!--            <draggable class="list-group" group="a">-->
            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.proje.tanimlama.tarih")'
                              name="10">
              <el-row :gutter="5" style="padding: 0 15px 0 15px">
                <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                  <el-form-item
                      :label='$t("src.views.apps.proje.tanimlama.baslamaTarihi")'
                      prop="baslangicTarihi">
                    <el-date-picker size="small" class="select-wide" type="date"
                                    :placeholder='"Tarih Seçiniz"'
                                    :picker-options="datePickerOptions()"
                                    v-model="projeForm.baslangicTarihi"
                                    format="dd.MM.yyyy"
                                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                  <el-form-item
                      :label='$t("src.views.apps.proje.tanimlama.bitisTarihi")'
                      prop="bitisTarihi">:
                    <el-date-picker size="small" class="select-wide" type="date"
                                    :placeholder='"Tarih Seçiniz"'
                                    :picker-options="datePickerOptions(projeForm.baslangicTarihi)"
                                    v-model="projeForm.bitisTarihi"
                                    format="dd.MM.yyyy"
                                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-collapse-item>
            <!-- <konumlar :modulName="'haber'" :resim="projeForm.resimKonum" :menu="projeForm.menuKonum" :galeri="projeForm.galeriKonum" v-on:changeKonum="handleKonum"></konumlar> -->
            <!--            </draggable>-->
            <el-collapse-item class="card-shadow--medium pt-10" name="8" :title='$t("src.views.apps.genel.guncelle")'>
              <el-row>
                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                  <el-button size="mini" v-on:click="projeGuncelle('projeForm')" class="onayBtn" type="primary">
                    <label>Güncelle</label>
                  </el-button>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
    <cakisma-dialog v-if="dialogVisibleYonetici" :dialogvisible.sync="dialogVisibleYonetici"
                    :userdata="userDataBGSurec"></cakisma-dialog>
  </div>
</template>
<script>
import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
// import draggable from "vuedraggable";
import haberService from '../../../WSProvider/HaberService'
import musteriService from '../../../WSProvider/MusteriService'
import projeService from '../../../WSProvider/ProjeService'
import sozlesmeService from '../../../WSProvider/SozlesmeService'
import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from '@/components/event-bus'
import CakismaDialog from "@/components/Cakisma.vue";
// import {json} from 'body-parser';
var moment = require('moment');
let time = moment().format('YYYY-MM-DD');

export default {
  name: "ProjeGuncelle",
  components: {CakismaDialog},
  watch: {
    sozlesmeKey(val) {
      this.seciliSozlesmeFiltre();
      this.seciliSozlesmeler.length > 0 ? this.sozlesmeTarihBaslikGoster = true : this.sozlesmeTarihBaslikGoster = false
    },
    storeData(val) {
      this.projeForm.projeID = this.$store.getters.getProjeUpdateData
      this.getSozlesmeListesi();
      // this.getProjeDetay();

    },
    kontrol(newVal) {
      if (newVal) {
        this.cakismaKontrol();
      }
    },
  },
  computed: {
    storeData() {
      return this.$store.getters.getProjeUpdateData
    },
    tatilList() {
      return this.$store.getters.getTatilList
    },
    pickerOptions() {
      const self = this
      return {
        firstDayOfWeek: 1,
        disabledDate(time) {
          const dateList = []
          self.tatilList.forEach(item => dateList.push(new Date(item.baslamaTarihi).toString()))
          const veri = dateList.find(item => item == time)
          return veri
        }
      }
    },
  },
  created() {
    this.checkDevice();
  },
  mounted() {
    window.addEventListener('resize', this.checkDevice);
    this.projeForm.projeID = this.$store.getters.getProjeUpdateData
    this.getSozlesmeListesi();
    // this.getProjeDetay();
    this.getMusteriListesi();
    this.getUlkeListesi();
    this.sozlesmeList.forEach(el => {
      el.secili = false;
      el.baslamaTarihi = '';
      el.isTeslimTarihi = '';
    })
    this.sozlesmeKey++;
    const self = this;
    this.disabledDatesFilter()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },
  data() {
    return {
      disabledTarihler: [],
      dialogVisibleYonetici: false,
      count: [],
      kontrol: false,
      cakisma: "",
      cakismaIDs: [],
      allSubArraysEmpty: false,
      isDesktop: false,
      denemekey: 0,
      denemekey2: 0,
      projeData: {},
      musteriler: [],
      ulkeler: [],
      iller: [],
      ilceler: [],
      sozlesmeList: [],
      gruplanmisSozlesmeler: [],
      tumunuSec: false,
      sozlesmeKey: 0,
      loading: false,
      sozlesmeLoading: false,
      selectedImagesFromServer: [],
      path: haberService.path,
      imagePath: haberService.imagePath,
      belgePath: haberService.belgePath,
      editor: ClassicEditor,
      editorConfig: {
        language: 'tr',
      },
      activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      visibleSil: false,
      disabled: false,
      options: [],
      dateRangeOptions: {
        firstDayOfWeek: 1
      },
      seciliSozlesmeler: [],
      sozlesmeTarihBaslikGoster: false,
      projeForm: {
        kategori: '',
        musteriAdi: '',
        projeBaslik: '',
        alan: '',
        ada: '',
        parsel: '',
        musteriID: '',
        ulkeID: '',
        ulkeAdi: '',
        ilID: '',
        ilBaslik: '',
        ilceID: '',
        ilceBaslik: '',
        adresBaslik: '',
        baslangicTarihi: time,
        bitisTarihi: time,
        durum: '1',
        sozlesmeler: [],
        sozlesmeAtamalari: []
      },
      selectIcon: '1',
      page: 0,
      postPage: 0,
      sizes: 10,
      rulesProje: {
        projeBaslik: [{
          required: true,
          message: "Lütfen Proje Adı Giriniz",
          trigger: 'blur'
        }],
        musteriAdi: [{
          required: true,
          message: "Lütfen Firma Seçiniz",
          trigger: 'blur'
        }],
        alan: [{
          required: true,
          message: "Lütfen Alan Giriniz",
          trigger: 'blur'
        }],
        ada: [{
          required: true,
          message: "Lütfen Ada Giriniz",
          trigger: 'blur'
        }],
        parsel: [{
          required: true,
          message: "Lütfen Parsel Giriniz",
          trigger: 'blur'
        }],
        eposta: [
          {required: true, message: "Lütfen E-posta Giriniz", trigger: 'blur'},
          {type: 'email', message: this.$t('src.views.apps.messages.enterValidMail'), trigger: 'blur'},
        ],
        adresBaslik: [{
          required: true,
          message: "Lütfen Adres Giriniz",
          trigger: 'blur'
        }],
        ulkeID: [{
          required: true,
          message: "Lütfen Ülke Seçiniz",
          trigger: 'blur'
        }],
        ilID: [{
          required: true,
          message: "Lütfen İl Seçiniz",
          trigger: 'blur'
        }],
        ilceID: [{
          required: true,
          message: "Lütfen İlçe Seçiniz",
          trigger: 'blur'
        }],
        baslangicTarihi: [{
          required: true,
          message: "Lütfen Tarih Seçiniz",
          trigger: 'blur'
        }],
        bitisTarihi: [{
          required: true,
          message: "Lütfen Tarih Seçiniz",
          trigger: 'blur'
        }],
      },
      durumOptions: [
        {
          label: 'Aktif',
          key: '10'
        },
        {
          label: 'Pasif',
          key: '30'
        },
        {
          label: 'Yapıldı',
          key: '20'
        },
        {
          label: 'Kapsam Dışı',
          key: '0'
        },
      ],
    }
  },
  methods: {
    disabledDatesFilter() {
      var araTarih = function (startDate, endDate) {
        var now = startDate.clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format('YYYY-MM-DD'));
          now.add(1, 'days');
        }
        return dates;
      };
      this.tatilList.forEach(item => araTarih(moment(item.baslamaTarihi), moment(item.bitisTarihi)).forEach(veri => this.disabledTarihler.push(veri)))
    },
    datePickerOptions(baslamaTarihi) {
      const self = this
      return {
        firstDayOfWeek: 1,
        disabledDate: date => {
          const disabledTarihler = self.disabledTarihler.find(item => moment(item).format("MMM Do YY") == moment(date).format("MMM Do YY"))
          return date < new Date(baslamaTarihi) || disabledTarihler
        }
      };
    },
    sozlesmeDatePickerOptions(baslamaTarihi) {
      const self = this
      return {
        firstDayOfWeek: 1,
        disabledDate: date => {
          const disabledTarihler = self.disabledTarihler.find(item => moment(item).format("MMM Do YY") == moment(date).format("MMM Do YY"))
          if (baslamaTarihi != undefined) return date < new Date(baslamaTarihi) || disabledTarihler
          else return date < new Date(this.projeForm.baslangicTarihi) || disabledTarihler
        }
      };
    },
    open(sozlesmeID) {
      const foundSubArray = this.cakisma.find(subArray => {
        return subArray.some(item => item.sozlesmeID === sozlesmeID);
      });
      if (foundSubArray) {
        this.userDataBGSurec = foundSubArray;
        this.dialogVisibleYonetici = true;
      }
    },
    sozlesmeAtama(sozlesme) {
      const index = this.seciliSozlesmeler.findIndex(item => item.sozlesmeID == sozlesme.sozlesmeID)
      const data = this.seciliSozlesmeler.find(item => item.sozlesmeID == sozlesme.sozlesmeID)
      if (data == undefined) {
        this.seciliSozlesmeler.push(sozlesme)
      } else {
        this.seciliSozlesmeler.splice(index, 1)
        this.seciliSozlesmeler.push(data)
      }
    },
    inputChange(val, sozlesme) {
      const value = this.formatYuzdeDeger(val)
      sozlesme.yuzdeDeger = value
      const data = this.seciliSozlesmeler.find(item => item.sozlesmeID == sozlesme.sozlesmeID)
      if (data == undefined) {
        this.seciliSozlesmeler.push(sozlesme)
      }
    },
    cakismaKontrol() {
      const sozlesmeCakismalari = this.projeForm.sozlesmeAtamalari.map(item => ({
        sozlesmeID: item.sozlesmeID,
        baslamaTarihi: item.baslamaTarihi,
        tamamlanmaTarihi: item.isTeslimTarihi
      }));
      sozlesmeService.kesisenSozlesmeler2(JSON.stringify(sozlesmeCakismalari)).then((response) => {
        localStorage.setItem("userDataBGSurec", response.token)
        if (response.status == 200) {
          this.cakisma = response.data;
          this.allSubArraysEmpty = !(this.cakisma.every(subArray => subArray.length === 0));
          // console.log("-----")
          // console.log(this.cakisma)
          this.cakisma.forEach(subArray => {
            if (Array.isArray(subArray) && subArray.length > 0) {
              subArray.forEach(item => {
                this.cakismaIDs.push(item.sozlesmeID);
              });
            }
          });
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.kontrol = false;
      });
    },
    formatYuzdeDeger(yuzdeDeger) {
      return yuzdeDeger.replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, ".");
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
    validateParsel() {
      const regex = /^[0-9!@#\$%\^\&*\)\(+=._-]+$/;

      if (!regex.test(this.projeForm.parsel)) {
        this.projeForm.parsel = this.projeForm.parsel.replace(/[^0-9!@#\$%\^\&*/\)\(+=._-]+$/, '');
      }
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;

      // this.getMusteriSayisi();
      this.getMusteriListesi();
      this.getUlkeListesi();


      // this.getIlListesi();
    },
    grupTumunuSec(grup) {
      const secili = grup.grupSwitchState
      this.sozlesmeTarihBaslikGoster = secili == 1
      if (secili == 0) {
        grup.sozlesmeler.forEach(item => {
          item.secili = "0"
          item.status = '30'
          item.durum = '30'
          const index = this.projeForm.sozlesmeAtamalari.findIndex(sozlesmeID => sozlesmeID === item.sozlesmeID);
          if (index !== -1) {
            this.projeForm.sozlesmeAtamalari.splice(index, 1);
          }
        });
      } else {
        grup.sozlesmeler.forEach(item => {
          this.seciliSozlesmeler.push(item)
          item.secili = "1"
          item.status = '10'
          item.durum = '10'
          if (!this.projeForm.sozlesmeAtamalari.includes(item.sozlesmeID)) {
            this.projeForm.sozlesmeAtamalari.push(item);
          }
        });
      }
    },
    atama(event, sozlesme) {
      if (event == 10) {
        this.sozlesmeTarihBaslikGoster = true
        this.projeForm.sozlesmeAtamalari.push(sozlesme);
      } else {
        const removeIndex = this.projeForm.sozlesmeAtamalari.findIndex(item => item.sozlesmeID == sozlesme.sozlesmeID)
        if (removeIndex != -1) this.projeForm.sozlesmeAtamalari.splice(removeIndex, 1)
      }
      this.seciliSozlesmeler.push(sozlesme)
      sozlesme.durum = event
      sozlesme.status = event
      this.count[sozlesme.sozlesmeID] = 1
      const deger = this.count
      this.count = []
      this.count = deger
    },
    // atama(sozlesme, secili) {
    //   var test = this.sozlesmeList.find(e => sozlesme.sozlesmeID == e.sozlesmeID)
    //   var sozlesme2 = this.projeForm.sozlesmeler.find(e => sozlesme.sozlesmeID == e.sozlesmeID)
    //   if (sozlesme2) {
    //     sozlesme.durum = sozlesme2.durum
    //   }
    //   this.seciliSozlesmeler.push(sozlesme)
    //   this.sozlesmeTarihBaslikGoster = secili == 1 ? true : false
    //   const index = this.projeForm.sozlesmeAtamalari.findIndex((item) => item.sozlesmeID === sozlesme.sozlesmeID);
    //   const currentGrup = this.gruplanmisSozlesmeler.find((grup) =>
    //       grup.sozlesmeler.some((sozlesmeItem) => sozlesmeItem.sozlesmeID === sozlesme.sozlesmeID)
    //   );
    //   if (secili == 1) {
    //     if (currentGrup.sozlesmeler.every((sozlesmeItem) => sozlesmeItem.secili === "1")) {
    //       currentGrup.grupSwitchState = "1";
    //     }
    //     if (index == -1) {
    //       this.projeForm.sozlesmeAtamalari.push(sozlesme);
    //     }
    //   } else {
    //     if (currentGrup) {
    //       currentGrup.grupSwitchState = "0";
    //     }
    //     if (index != -1) {
    //       this.projeForm.sozlesmeAtamalari.splice(index, 1);
    //     }
    //   }
    //
    // },
    getMusteriListesi() {
      try {
        this.loading = true;
        musteriService.musteriListesi(this.selectIcon, this.postPage, this.sizes).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.musteriler = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.musteriler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getUlkeListesi() {
      try {
        this.loading = true;
        projeService.ulkeGetir().then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.ulkeler = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.ulkeler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getIlListesi() {
      try {
        this.loading = true;
        this.projeForm.ilID = ''
        this.projeForm.ilceID = ''
        projeService.ilGetir(this.projeForm.ulkeID).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.iller = response.data;
          }
          this.getIlceListesi()
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.iller = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getIlceListesi() {
      try {
        this.loading = true;
        projeService.ilceGetir(this.projeForm.ilID).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.ilceler = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.ilceler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getSozlesmeListesi() {
      try {
        this.loading = true;
        sozlesmeService.sozlesmeListesi(this.selectIcon, this.postPage, "").then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.total = parseInt(response.data.sozlesmeSayisi);
            this.sozlesmeList = response.data;
            this.total = parseInt(response.count);
            const gruplanmisSozlesmeler = [];
            this.sozlesmeList.forEach((sozlesme) => {
              sozlesme.status = '30'
              const sozlesmeKategoriAdi = sozlesme.sozlesmeKategoriAdi;
              const sozlesmeKategoriID = sozlesme.sozlesmeKategoriID;
              const grupIndex = gruplanmisSozlesmeler.findIndex((grup) => grup.sozlesmeKategoriAdi === sozlesmeKategoriAdi);
              if (grupIndex !== -1) {
                // console.log('grupiddex farkli', gruplanmisSozlesmeler[grupIndex])
                gruplanmisSozlesmeler[grupIndex].sozlesmeler.push(sozlesme);
              } else {
                gruplanmisSozlesmeler.push({
                  sozlesmeKategoriAdi: sozlesmeKategoriAdi,
                  sozlesmeKategoriID: sozlesmeKategoriID,
                  sozlesmeler: [sozlesme]
                });
              }
            });
            this.gruplanmisSozlesmeler = gruplanmisSozlesmeler
          }
          this.getProjeDetay();
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.sozlesmeList = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getProjeDetay() {
      try {
        this.loading = true;
        projeService.projeDetay(this.projeForm.projeID).then(response => {
          if (response.status == 200) {
            console.log('sozlesmeler', response.data.sozlesmeler)
            localStorage.setItem("userDataBGSurec", response.token);
            this.projeForm.musteriID = response.data.musteriID
            this.projeForm.musteriAdi = response.data.musteriAdi
            this.projeForm.projeBaslik = response.data.projeBaslik
            this.projeForm.alan = response.data.alan
            this.projeForm.ada = response.data.ada
            this.projeForm.parsel = response.data.parsel
            this.projeForm.ulkeID = response.data.ulkeID
            this.projeForm.ulkeAdi = response.data.ulkeAdi
            this.getIlListesi();
            this.projeForm.ilID = response.data.ilID
            this.projeForm.ilBaslik = response.data.ilBaslik
            this.projeForm.ilceID = response.data.ilceID;
            this.projeForm.ilceBaslik = response.data.ilceBaslik;
            this.projeForm.adresBaslik = response.data.adresBaslik
            this.projeForm.baslangicTarihi = response.data.baslangicTarihi
            this.projeForm.bitisTarihi = response.data.bitisTarihi
            this.projeForm.sozlesmeler = response.data.sozlesmeler
            response.data.sozlesmeler.forEach(el => {
              var sozlesme = this.sozlesmeList.find(e => el.sozlesmeID == e.sozlesmeID)
              if (sozlesme) {
                sozlesme.baslamaTarihi = el.baslamaTarihi
                sozlesme.isTeslimTarihi = el.isTeslimTarihi
                sozlesme.yuzdeDeger = el.yuzdeDeger
                sozlesme.isSuresi = el.isSuresi
                sozlesme.baslangicSuresi = el.baslangicSuresi
                sozlesme.status = el.durum
                sozlesme.durum = el.durum
                sozlesme.secili = "1"
              }
            })
            // response.data.sozlesmeler.forEach(el => {
            //   var sozlesme = this.sozlesmeList.find(e => el.sozlesmeID == e.sozlesmeID)
            //   if (sozlesme) {
            //     sozlesme.baslamaTarihi = el.baslamaTarihi
            //     sozlesme.isTeslimTarihi = el.isTeslimTarihi
            //     sozlesme.yuzdeDeger = el.yuzdeDeger
            //     sozlesme.isSuresi = el.isSuresi
            //     sozlesme.baslangicSuresi = el.baslangicSuresi
            //     sozlesme.secili = "1"
            //   }
            // })

            // response.data.sozlesmeler.forEach(el => {
            //   var sozlesme = this.sozlesmeList.find(e => el.sozlesmeID == e.sozlesmeID)
            //   var sozlesme2 = this.projeForm.sozlesmeler.find(e => el.sozlesmeID == e.sozlesmeID)
            //   // console.log('sozlesme', sozlesme)
            //   if (sozlesme) {
            //     sozlesme.baslamaTarihi = el.baslamaTarihi
            //     sozlesme.isTeslimTarihi = el.isTeslimTarihi
            //     sozlesme.yuzdeDeger = el.yuzdeDeger
            //     sozlesme.isSuresi = el.isSuresi
            //     sozlesme.baslangicSuresi = el.baslangicSuresi
            //     sozlesme.secili = "1"
            //     sozlesme.durum = sozlesme2.durum
            //   }
            // })
            /*
            if(response.data.sozlesmeler.length == 47){
                this.tumunuSec = true
            }*/
            this.sozlesmeKey++
            this.projeData = response.data
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        this.loading = false;
      }
    },
    projeGuncelle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Projeyi güncellemek istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            this.addLoading = true;
            for (let i = 0; i < this.seciliSozlesmeler.length; i++) {
              if (this.seciliSozlesmeler[i].isTeslimTarihi > this.projeForm.bitisTarihi) {
                notification.Status("warning", this, "Sözleşmenin Tamamlanma Tarihi, Proje Bitiş Tarihinden Sonra Olamaz");
                this.loading = false;
                return;
              }
            }
            if (this.projeForm.baslangicTarihi >= this.projeForm.bitisTarihi) {
              notification.Status("warning", this, "Projenin Başlangıç Tarihi ile Projenin Bitiş Tarihi Aynı Olamaz")
              this.loading = false;
              return;
            }
            //this.projeForm.sozlesmeler = this.seciliSozlesmeler;
            const sozlesmeAtamalari = JSON.stringify(this.seciliSozlesmeler);
            //var update = functions.findObjectDifference(this.projeData, this.projeForm);
            var update = this.farkBul(this.projeData, this.projeForm)
            /* if(update.sozlesmeler) {
                 update.sozlesmeler.forEach(el => {
                     update.sozlesmeler  = update.sozlesmeler.map((el) => JSON.stringify(el)); //(el)
                 })
             }*/
            if (Object.keys(update).length > 0 || sozlesmeAtamalari) {
              projeService.projeGuncelle(this.projeForm.projeID, update, sozlesmeAtamalari).then((response) => {
                if (response.status == 200) {
                  this.getProjeDetay()
                  localStorage.setItem("userDataBGSurec", response.token)
                  notification.Status("success", this, response.msg)
                  EventBus.$emit("projelistesi", true)
                  functions.sayfaKapat("projeguncelle", this);
                  this.resetForm(formName)
                }
                this.addLoading = false;
              }).catch(err => {
                if (err.responseJSON) {
                  let error = err.responseJSON
                  if (error.status == 401) {
                    notification.Status(150, this);
                  } else notification.Status("errorMsg", this, error.errMsg)
                } else {
                  //notification.Status(503, this)
                }
                this.addLoading = false;
              })

            } else {
              notification.Status("warning", this, "Herhangi bir değişiklik bulunamadı!")
            }
          }).catch((e) => {
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.addLoading = false
          }
        }
      })
    },
    // datePickerOptions(baslamaTarihi) {
    //   return {
    //     firstDayOfWeek: 1,
    //     disabledDate: date => {
    //       // Başlama tarihinden önceki tarihler seçilemez
    //       return date < new Date(baslamaTarihi);
    //     }
    //   };
    // },
    farkBul(object1, object2) {
      const result = {};
      for (const key in object1) {
        if (object1.hasOwnProperty(key) && object2.hasOwnProperty(key)) {
          if (key === 'sozlesmeler' || key === 'sozlesmeAtamalari') {
            continue; // sozlesmeler veya sozlesmeAtamalari anahtarları için fark kontrolü yapma, bir sonraki iterasyona geç
          }
          if (object1[key] !== object2[key]) {
            result[key] = object2[key];
          }
        }
      }
      return result;
    },
    indexMethod(index) {
      return ((this.page - 1) * 10) + index + 1;
    },
    handleSizeChange(val) {
      this.sizes = val;
      this.getMusteriListesi();
      this.getUlkeListesi();
      // this.getSozlesmeListesi();
    },
    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getMusteriListesi();
      this.getUlkeListesi();
      // this.getSozlesmeListesi();
      document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getMusteriListesi();
      this.getUlkeListesi();
      // this.getSozlesmeListesi();
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    sozlesmeSec(item) {
      let total = parseInt(item.baslangicSuresi) + parseInt(item.isSuresi)
      let baslama = moment(this.projeForm.baslangicTarihi).add(item.baslangicSuresi, 'days')
      let bitis = moment(this.projeForm.baslangicTarihi).add(total, 'days')
      item.baslamaTarihi = moment(baslama).format('YYYY-MM-DD')
      item.isTeslimTarihi = moment(bitis).format('YYYY-MM-DD')
      this.kontrol = true
    },
    tumunuSecChange(event) {
      this.tumunuSec = this.tumunuSec
      this.sozlesmeList.forEach(el => el.secili = this.tumunuSec)
      this.seciliSozlesmeler = this.sozlesmeList.filter(el => el.secili == this.tumunuSec)
      this.sozlesmeTarihBaslikGoster = this.tumunuSec
    },
    baslamaSuresiDegistir(event, item) {
      let secilenProjeTarihi = moment(this.projeForm.baslangicTarihi);
      let baslamaTarihi = moment(item.baslamaTarihi);
      var fark = Math.abs(baslamaTarihi.diff(secilenProjeTarihi, 'days'));
      item.baslangicSuresi = fark
    },
    bitisSuresiDegistir(event, item) {
      let baslamaTarihi = moment(item.baslamaTarihi);
      let isTeslimTarihi = moment(item.isTeslimTarihi);
      var fark = Math.abs(isTeslimTarihi.diff(baslamaTarihi, 'days'));
      item.isSuresi = fark
    },
    seciliSozlesmeFiltre() {
      this.seciliSozlesmeler = [];
      this.seciliSozlesmeler = this.sozlesmeList.filter(el => el.secili == true)
      this.projeForm.sozlesmeAtamalari = this.sozlesmeList.filter(el => el.secili == true)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
    },
    /*
    farkBul(object1, object2){ //object1 -> eski object2 - > güncel değerler
        const result = {};
        for (const key in object1) {
            if (object1.hasOwnProperty(key) && object2.hasOwnProperty(key)) {
                if (key === 'sozlesmeler') {
                    const sozlesmeDifferences = [];
                    for (const sozlesme of object1[key]) {
                        const guncelSozlesme = object2[key].find(s => s.sozlesmeID === sozlesme.sozlesmeID);
                        if (guncelSozlesme) {
                            const difference = {};
                            if (sozlesme.yuzdeDeger !== guncelSozlesme.yuzdeDeger) {
                                difference.yuzdeDeger = guncelSozlesme.yuzdeDeger;
                            }
                            if (sozlesme.baslangicSuresi !== guncelSozlesme.baslangicSuresi) {
                                difference.baslangicSuresi = guncelSozlesme.baslangicSuresi;
                            }
                            if (sozlesme.isSuresi !== guncelSozlesme.isSuresi) {
                                difference.isSuresi = guncelSozlesme.isSuresi;
                            }
                            if (sozlesme.baslamaTarihi !== guncelSozlesme.baslamaTarihi) {
                                difference.baslamaTarihi = guncelSozlesme.baslamaTarihi ;
                            }
                            if (sozlesme.isTeslimTarihi !== guncelSozlesme.isTeslimTarihi) {
                                difference.isTeslimTarihi = guncelSozlesme.isTeslimTarihi;
                            }
                            if (Object.keys(difference).length > 0) {
                                sozlesmeDifferences.push({projeAtamaID: sozlesme.projeAtamaID, ...difference});
                            }
                        }
                    }
                    if (sozlesmeDifferences.length > 0) {
                        result.sozlesmeler = sozlesmeDifferences;
                    }
                }
                else {
                    if (object1[key] !== object2[key]) {
                        result[key] = object2[key];
                    }
                }
            }
        }
        return result;
    },*/
  }
}

</script>
<style lang="scss">
.sozlesmeSelect {
  .el-row {
    padding: 10px 10px 10px 8px;
  }

  .el-row:nth-child(2n) {
    background: #f3f7fa !important
  }
}
</style>
<style>
.flex-box {
  display: flex;
  justify-content: flex-end;
}

.kapsamlar .el-collapse-item .el-collapse-item__header {
  color: white;
  text-align: center;
  font-size: 15px !important;
  padding: 0px 10px;
  font-weight: 400;
  border-radius: 8px;
}

.kapsamlar .el-collapse-item:nth-child(1) .el-collapse-item__header {
  background-color: #28323b;
}

.kapsamlar .el-collapse-item:nth-child(2) .el-collapse-item__header {
  background-color: #006cae;
}

.kapsamlar .el-collapse-item:nth-child(3) .el-collapse-item__header {
  background-color: #3595cf;
}

.kapsamlar .el-collapse-item:nth-child(4) .el-collapse-item__header {
  background-color: #ff8b00;
}
</style>